import { Link, Text, TextType } from '@qnergy/component-library';
import React from 'react';
import logo from '../images/QD.png';

export default function Moved(): JSX.Element {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center gap-12 p-4">
      <Text type={TextType.h1} wrap className="text-center">
        This website has been discontinued.
      </Text>
      <Text type={TextType.h4} wrap className="text-center">
        {' '}
        To access CAP3 systems, please use
      </Text>
      <div className="flex flex-col items-center">
        <Link className="w-1/2" href="https://dashboard.qnergy.com">
          <img src={logo}></img>
        </Link>
        <Link href="https://dashboard.qnergy.com">(click here go to the Qnergy Dashboard)</Link>
      </div>
    </div>
  );
}
